import styled from 'styled-components';
import { animated } from 'react-spring';
import media from 'styles/media';

export const HeaderFoot = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
`;

export const Line = styled(animated.div)`
  flex: 1;
  display: block;
  height: 1px;
  background: #005388;
`;

export const SectionHeadingStyle = styled(animated.h1)`
  display: block;
  width: 100%;
  text-align: center;
  color: #005388;
  margin-top: 1rem;
  @media ${media.small} {
    font-size: 2.5rem;
  }
`;

export const BoneSVG = styled(animated.svg)`
  flex: 1;
  display: block;
  margin: 0 auto;
  max-width: 85px;
  fill: #005388;
  padding: 0 1rem;
`;
