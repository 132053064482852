import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const NavLinks = ({ closeMenuClick }) => {
  const siteNavLinks = {
    link1: {
      title: 'home',
      link: '/',
      className: '',
      internal: true,
    },
    link2: {
      title: 'about',
      link: '/about',
      className: '',
      internal: true,
    },
    link3: {
      title: 'contact',
      link: '/contact',
      className: '',
      internal: true,
    },
    link4: {
      title: 'buy now',
      link: 'https://www.amazon.com/dp/108702160X?ref_=pe_3052080_397514860',
      className: 'cta-round',
      internal: false,
    },
  };
  return (
    <>
      {Object.keys(siteNavLinks).map((name, index) => (
        <li key={index}>
          {siteNavLinks[name].internal ? (
            <Link
              to={siteNavLinks[name].link}
              className={siteNavLinks[name].className}
              onClick={closeMenuClick}
            >
              {siteNavLinks[name].title}
            </Link>
          ) : (
            <a
              href={siteNavLinks[name].link}
              className={siteNavLinks[name].className}
              onClick={closeMenuClick}
            >
              {siteNavLinks[name].title}
            </a>
          )}
        </li>
      ))}
    </>
  );
};

NavLinks.propTypes = {
  closeMenuClick: PropTypes.func,
};

export default NavLinks;
