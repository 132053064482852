import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from 'styles/global';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Layout = (props) => {
  const { children } = props;
  return (
    <div>
      <GlobalStyle />
      <Header />
      <div className="main">{children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
