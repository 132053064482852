import { createGlobalStyle } from 'styled-components';
import layout from 'styles/layout';
import media from 'styles/media';

const GlobalStyle = createGlobalStyle`
  /*
  Normalize
  */
  html{box-sizing:border-box}*,::after,::before{box-sizing:inherit}:root{-moz-tab-size:4;tab-size:4}html{-webkit-text-size-adjust:100%}body{margin:0}hr{height:0}abbr[title]{text-decoration:underline dotted}code,kbd,pre,samp{font-family:SFMono-Regular,Consolas,'Liberation Mono',Menlo,Courier,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{padding:0}progress{vertical-align:baseline}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}summary{display:list-item}
  /*
  END Normalize
  */
  /*
  Global Styles
  */
   html{
    box-sizing: border-box;
  }
  *, *::before, *::after{
    box-sizing: border-box;
  }
  body{
    padding: 0;
    margin: 0;
    color: #005388;
    background: #0889af;
  }
  a{
    text-decoration: none;
    color: #fff;
  }
  h1,h2,h3,h4,h5,h6{
    color: #005388;
    font-weight: 400;
  }
  p{
    font-weight: 300;
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  h2{
    @media screen and (min-width: 762px){
      font-size: 1.75rem;
    }
    @media screen and (min-width: 992px){
      font-size: 2rem;
    }
  }
  div.main{
    position: relative;
    z-index: 8888;
    margin: 0 auto;
    padding-top: 62px;
    background: #fff;
    @media ${media.small} {
      padding-top: 72px;
    }
    @media ${media.medium} {
      padding-top: 92px;
      min-height: 900px;
    }
  }
  section{
    width: calc(100% - 3rem);
    max-width: ${layout.contentWidth};
    margin: 0 auto;
    padding: 1.5rem 0;
    min-width: 280px;
    @media ${media.small} {
      padding: 2.5rem 0;
    }
    &:first-child{
      padding-top: 4rem;
    }
  }
`;

export default GlobalStyle;
