import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, useChain } from 'react-spring';
import NavLinks from './NavLinks';
import {
  DesktopNav, MobileMenuWrap, Inner, MobileMenu,
} from './styles';

const Navigation = ({ menuOpen, handleMenuClick }) => {
  // Mobile navigation menu animation
  const menuAnimationRef = useRef();
  const menuAnimation = useSpring({
    transform: menuOpen
      ? `translate3d(0, -50%, 0) scale(3)`
      : `translate3d(100%, -50%, 0) scale(0)`,
    opacity: menuOpen ? `1` : `0`,
    ref: menuAnimationRef,
  });

  // Menu links animation
  const menuInnerLinksRef = useRef();
  const menuInnerLinks = useSpring({
    opacity: menuOpen ? `1` : `0`,
    ref: menuInnerLinksRef,
  });

  useChain(
    menuOpen
      ? [menuAnimationRef, menuInnerLinksRef]
      : [menuInnerLinksRef, menuAnimationRef],
    [0, 0.25],
  );

  // Close menu on link selection
  const closeMenuClick = () => {
    handleMenuClick();
  };

  return (
    <>
      <DesktopNav>
        <NavLinks />
      </DesktopNav>
      <MobileMenuWrap className={menuOpen ? 'menu-active' : ''}>
        <animated.div className="menu-background" style={menuAnimation} />
        <Inner style={menuInnerLinks}>
          <MobileMenu>
            <NavLinks closeMenuClick={closeMenuClick} />
          </MobileMenu>
        </Inner>
      </MobileMenuWrap>
    </>
  );
};

Navigation.propTypes = {
  menuOpen: PropTypes.bool,
  handleMenuClick: PropTypes.func,
};

export default Navigation;
