import styled from 'styled-components';
import media from 'styles/media';
import layout from 'styles/layout';

export const Brand = styled.div`
  width: 100%;
  @media ${media.medium} {
    max-width: 350px;
    text-align: left;
  }
  a {
    display: block;
    span {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      align-content: space-between;
      h6 {
        position: relative;
        top: 35px;
        color: #fff;
        margin: 0 auto;
        @media ${media.small} {
          position: static;
          flex: 1;
          opacity: 0;
          text-align: center;
          margin: 0;
          color: white;
          text-align: right;
          &:last-of-type {
            text-align: left;
          }
        }
        @media ${media.medium} {
          display: none;
        }
      }
    }
    // Site brand image
    img#site_brand {
      flex: 1;
      display: block;
      width: 100%;
      max-width: 75px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      transition: all 300ms;
      transform: translate3d(0, 30px, 0);
      @media ${media.small} {
        max-width: 100px;
        transform: translate3d(0, 50px, 0);
        &.minimized {
          transform: translate3d(0, 35px, 0) scale(0.7);
        }
      }
      @media ${media.medium} {
        transform: translate3d(20px, 35px, 0) scale(1);
        &.minimized {
          transform: translate3d(20px, 35px, 0) scale(1);
        }
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  h3 {
    display: none;
    @media ${media.medium} {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 200px;
      opacity: 0;
      color: #fff;
      font-size: 1.31951rem;
      margin: 0;
      transition: opacity 300ms;
      small {
        display: block;
        font-size: 65%;
      }
    }
  }
`;

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background: #0889af;
  border-bottom: 22px solid #005388;
  .bar {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 50px;
    max-width: ${layout.maxWidth};
    align-items: center;
    margin: 0 auto;
    @media ${media.medium} {
      justify-content: space-between;
      height: 70px;
    }
  }
  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  @media ${media.medium} {
    position: static;
    width: auto;
  }
`;

export const MenuButton = styled.button`
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 9999;
  color: #fff;
  border: none;
  font-weight: 400;
  line-height: 0;
  text-align: center;
  height: 50px;
  text-transform: uppercase;
  background: transparent;
  @media ${media.medium} {
    display: none;
  }
`;
