import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { useSpring } from 'react-spring';
import { easeCubicOut } from 'd3-ease';
import {
  HeaderFoot, Line, SectionHeadingStyle, BoneSVG,
} from './styles';

const Heading = ({ text }) => {
  const [on, setOn] = useState(false);

  // Animations
  const fadeIn = useSpring({
    from: { transform: `translate3d(0,80%,0)`, opacity: 0 },
    opacity: on ? 1 : 0,
    transform: on ? `translate3d(0,0,0)` : `translate3d(0,80%,0)`,
    config: {
      duration: 500,
      ease: easeCubicOut,
    },
  });

  const rotateIn = useSpring({
    from: { transform: `rotate(0deg)`, opacity: 0 },
    opacity: on ? 1 : 0,
    transform: on ? `rotate(360deg)` : `rotate(0deg)`,
  });

  return (
    <>
      <SectionHeadingStyle style={fadeIn}>{text}</SectionHeadingStyle>
      <HeaderFoot>
        <Line style={fadeIn} />
        <BoneSVG style={rotateIn} viewBox="0 0 83.25 46.29" xmlSpace="preserve">
          <path
            className="cls-1"
            d="M10.3,10.22a10.71,10.71,0,0,1,10.47,4.87,1.53,1.53,0,0,0,1.94.73c11.68-2,23.12-4.14,34.8-6.09,1-.24,1.21-.48,1.46-1.21A11.45,11.45,0,0,1,69.91,0,11.61,11.61,0,0,1,80.62,8a10.14,10.14,0,0,1-.49,7.54,1.91,1.91,0,0,0,.25,1.95,11.15,11.15,0,0,1-1.46,16.3,11,11,0,0,1-16.06-2.68,1.54,1.54,0,0,0-2-.73c-11.68,1.95-23.36,4.14-35,6.09-1,.24-1.21.48-1.46,1.46A11.18,11.18,0,1,1,3.25,30.9c.48-1,.24-1.46-.49-2.19A11.1,11.1,0,0,1,.57,17.76,12.12,12.12,0,0,1,10.3,10.22ZM77.46,10a7.43,7.43,0,0,0-7.79-6.33,7.58,7.58,0,0,0-7.3,6.81c-.24,1.46-.73,2.19-2.19,2.44L21.5,19.47A2.49,2.49,0,0,1,18.58,18,7.59,7.59,0,0,0,11,13.38a7.59,7.59,0,0,0-6.57,5.36,7.74,7.74,0,0,0,2.68,8.51c1.46,1.22,1.46,1.7.49,3.16a7.27,7.27,0,0,0,.24,8.76,8,8,0,0,0,8,2.92,7.54,7.54,0,0,0,5.6-6.81c.24-1.46.73-2.19,2.18-2.43l38.69-6.57a2.47,2.47,0,0,1,2.92,1.46,7.59,7.59,0,0,0,7.54,4.62A8.19,8.19,0,0,0,79.65,27,7.75,7.75,0,0,0,77,18.49c-1.46-1.21-1.46-1.7-.49-3.16A7.9,7.9,0,0,0,77.46,10Z"
          />
        </BoneSVG>
        <Line style={fadeIn} />
      </HeaderFoot>
      <Waypoint bottomOffset="10%" onEnter={() => setOn(true)} />
    </>
  );
};

Heading.propTypes = {
  text: PropTypes.string,
};

export default Heading;
