import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { animated, useSpring, config } from 'react-spring';
import Navigation from 'components/Header/Navigation';
import SiteBrandImg from 'images/brand/gg-brand.svg';
import {
  Brand, Wrapper, NavWrapper, MenuButton,
} from './styles';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Fade-in/out site title animation
  const fadeBrandTitle = useSpring({
    opacity: isScrolled ? '1' : '0',
    config: config.gentle,
  });

  // Update brand animation state on scoll
  // to fire animation
  function checkPageScroll() {
    if (window.pageYOffset > 300) {
      return (
        setIsScrolled(true),
        document.getElementById('site_brand').classList.add('minimized')
      );
    }
    return (
      setIsScrolled(false),
      document.getElementById('site_brand').classList.remove('minimized')
    );
  }

  // Update state on click to open and close menu
  function handleMenuClick() {
    return setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    document.addEventListener('load', checkPageScroll());
    document.addEventListener('scroll', checkPageScroll);
    return () => {
      document.removeEventListener('scroll', checkPageScroll);
    };
  });

  return (
    <>
      <Wrapper id="site_masthead">
        <div className="bar">
          <Brand>
            <Link to="/">
              <span>
                <animated.h6 style={fadeBrandTitle}>
                  Gentle Grooming
                </animated.h6>
                <img
                  id="site_brand"
                  src={SiteBrandImg}
                  alt="Gentle Grooming Book"
                />
                <animated.h6 style={fadeBrandTitle}>
                  By: Cheri McCoy
                </animated.h6>
                <animated.h3 style={fadeBrandTitle}>
                  Gentle Grooming
                  <small>By: Cheri McCoy</small>
                </animated.h3>
              </span>
            </Link>
          </Brand>
          <NavWrapper>
            <Navigation menuOpen={menuOpen} handleMenuClick={handleMenuClick} />
          </NavWrapper>
        </div>
      </Wrapper>
      <MenuButton onClick={handleMenuClick}>
        {menuOpen ? 'Close' : 'Menu'}
      </MenuButton>
    </>
  );
};

export default Header;
