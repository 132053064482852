import React from 'react';
import { Wrapper } from './styles';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Wrapper>
      <div className="content">
        <p>{`Copyright © ${year} Gentle Grooming Book`}</p>
      </div>
    </Wrapper>
  );
};

export default Footer;
