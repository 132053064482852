import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  width: 100%;
  text-align: center;
  max-width: 100%;
  min-height: 150px;
  padding-bottom: 80px;
  margin: 0;
  background: #0889af;
  border-top: 22px solid #005388;
  @media ${media.medium} {
    padding-bottom: 0;
  }

  p {
    font-size: 0.875rem;
    color: white;
  }
`;
