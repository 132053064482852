import styled from 'styled-components';
import { animated } from 'react-spring';
import media from 'styles/media';

export const DesktopNav = styled.ul`
  display: none;
  @media ${media.medium} {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    margin: 0;
    padding: 0 3rem 0 0;
    background: transparent;
    li {
      margin-bottom: 0;
      transition: margin-right 300ms;
    }
    a,
    button {
      position: relative;
      display: flex;
      flex: auto 0 1;
      align-items: center;
      color: #fff;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 1.15rem;
      padding: 0 2rem;
      background: none;
      border: 0;
      cursor: pointer;
      transition: color 300ms;
      &:after {
        height: 2px;
        background: #fff;
        content: '';
        width: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.4s;
        transition-timing-function: cubic-bezier(1, 0, 0, 1);
        left: 50%;
        top: 2rem;
      }
      &:hover,
      &:focus {
        outline: none;
        color: #fff;
        text-decoration: none;
        &:after {
          width: calc(100% - 40px);
        }
      }
      &.cta-round {
        color: #0889af;
        background: white;
        text-transform: uppercase;
        border-radius: 50px;
        transition: all 300ms;
        &:hover {
          color: white;
          background: #ff6c00;
        }
        &::after {
          display: none;
        }
      }
    }
  }
`;

// Mobile Menu Styles
export const MobileMenuWrap = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  .menu-background {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: #016f8f;
    border-radius: 100%;
    @media ${media.medium} {
      display: none;
    }
  }
  &.menu-active {
    pointer-events: all;
  }
`;

export const Inner = styled(animated.div)`
  position: relative;
  opacity: 0;
  z-index: 100;
  width: 100%;
  max-width: 300px;
  @media ${media.medium} {
    display: none;
  }
`;

export const MobileMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  li {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    &:first-of-type {
      border: none;
    }
    &:last-of-type {
      border: none;
    }
  }
  a,
  button {
    display: block;
    position: relative;
    color: white;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    font-weight: 200;
    font-size: 1.25rem;
    padding: 0.5rem 0;
    margin: 1rem 0;
    background: none;
    &.cta-round {
      text-transform: uppercase;
      color: #0889af;
      background: #fff;
      border-radius: 50px;
      &:first-of-type {
        margin-top: 4rem;
      }
    }
  }
`;
